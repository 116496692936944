#app {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: 72px 287px 1fr;
  @media screen and (max-width: 1080px) {
    grid-template-columns: 72px 180px 1fr;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }

  * {
    box-sizing: border-box;
  }
}

.contents {
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    padding: 57px 0 0;
  }
}

input {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}
