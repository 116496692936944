.pageTitle {
  display: flex;
  width: 100%;
  border-bottom: 1px solid;
  h2 {
    display: flex;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 2px;
    line-height: 1;
    padding: 8px 16px 16px 16px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
      flex-direction: column;
      padding: 12px 8px 16px 8px;
    }
  }

  p {
    font-size: 14px;
    align-self: flex-end;
    padding: 0 0 0 40px;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 14px;
      line-height: 1.6;
      padding: 10px 0 0;
    }
  }

  &__viewMore {
    display: flex;
    align-items: center;
    font-size: 16px;
    align-self: flex-end;
    margin: 0 0 0 auto;

    svg {
      width: 10px;
      height: 13px;
      margin: 0 0 0 4px;
    }
  }
}
