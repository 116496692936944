.editor {
  &__wrap {
    min-height: 500px;
    border: 1px solid;

    figure {
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
}
.rdw-image-modal {
  color: #000;
}
.rdw-dropdown-optionwrapper {
  color: #000;
}
.rdw-dropdown-selectedtext {
  color: #000;
}
.rdw-editor-main {
  padding: 8px;
  min-height: 410px;
}
.textarea {
  width: 100%;
  box-sizing: border-box;
  min-height: 200px;
}

.tool-bar {
  &__popup {
    color: #000;
    transform: translate(calc(-50% + 20px), 0px);
  }
}
