.qanda {
  &__link-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    a {
      display: block;
      font-size: 18px;
      padding: 16px;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
